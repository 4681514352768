export enum eRPath {
  root = '/home',
  home = '/home',
  analytics = '/analytics',
  map = '/map',
  signin = '/auth/signin',
  signup = '/auth/signup',
  forgot = '/auth/forgot-password',
  enquiry = '/auth/enquiry',
  vehicleRoutes = '/map/vehicle-routes',
  vehicleDailyActivity = '/map/vehicle-daily-activity',
  rabbitTrail = '/map/rabbit-trail',
  rabbitTrailActivity = '/map/rabbit-trail-activity',
  rabbitTrailDailyActivity = '/map/rabbit-trail-daily-activity',
  pointOfInterestAdd = '/map/point-of-interest-add',
  pointOfInterestEdit = '/map/point-of-interest-edit',
  fenceAdd = '/map/fence-add',
  fenceEdit = '/map/fence-edit',
  'timeline' = '/map/timeline',
  'harshEvents' = '/map/harsh-events',
  'OBD2Event' = '/map/obd2-event',
  'repairRequest' = '/repair-request',
  'repairSummary' = '/repair-request/summary',
  'repairDiagnostics' = '/repair-request/add-diagnostics',
  'repairComments' = '/repair-request/comments',
  'repairAdd' = '/repair-request/add',
  'tripInspections' = '/trip-inspections',
  'reminder' = '/reminder-profiles',
  'vehicle' = '/vehicle',
  'vehicleAdd' = '/vehicle/add',
  'vehicleView' = '/vehicle/view',
  'vehicleEdit' = '/vehicle/edit',
  'vehicleReq' = '/vehicle/view/repair-request',
  'vehicleInitialMaintenance' = '/vehicle/initial-maintenance',
  'diAdd' = '/diagnostics-titles/add',
  'diEdit' = '/diagnostics-titles/edit',
  'diView' = '/diagnostics-titles/view',
  'it' = '/inspection-titles',
  'itAdd' = '/inspection-titles/add',
  'itEdit' = '/inspection-titles/edit',
  'itView' = '/inspection-titles/view',
  'dtiles' = '/diagnostics-titles',
  'manageVehicles' = '/manage-vehicles',
  'mvVehicleTypesAdd' = '/manage-vehicles/types-add',
  'mvVehicleTypesEdit' = '/manage-vehicles/types-edit',
  'mvVehicleTypesView' = '/manage-vehicles/types-view',
  'mvVehicles' = '/manage-vehicles/vehicles-list',
  'mvVehiclesRetired' = '/manage-vehicles/vehicles-retired',
  'mvDivisions' = '/manage-vehicles/divisions',
  'mvDivisionsAdd' = '/manage-vehicles/divisions-add',
  'mvDivisionsEdit' = '/manage-vehicles/divisions-edit',
  'mvDivisionsView' = '/manage-vehicles/divisions-view',
  'mvAddVehicle' = '/manage-vehicles/add-vehicle',
  'diagnostics' = '/diagnostics',
  'timesheet' = '/timesheet',
  'timesheetPdf' = '/timesheet/tsPdf',
  'timesheetSummary' = '/timesheet/summary',
  'companies' = '/companies',
  'companyAdd' = '/companies/add',
  'companyEdit' = '/companies/edit',
  'companyView' = '/companies/view',
  'vtl' = '/video-training-library',
  'vtlUpload' = '/video-training-library/uploadVideo',
  'vtlNewPlaylist' = '/video-training-library/newPlaylist',
  'vtlVideoDetails' = '/video-training-library/videoDetails',
  'vtlPlaylistDetails' = '/video-training-library/playlistDetails',
  'vtlPlaylistEdit' = '/video-training-library/playlistEdit',
  'estimate' = '/estimate',
  'estimateAdd' = '/estimate/add',
  'estimateDetails' = '/estimate/details',
  'timesheetAdd' = '/timesheet/add',
  'timesheetEdit' = '/timesheet/edit',
  'tripInspectionsAdd' = '/trip-inspections/add',
  'serviceRecords' = '/service-records',
  'repairFormSummary' = '/service-records/summary',
  'repairFormAdd' = '/service-records/repair-form',
  'repairFormEdit' = '/service-records/repair-form-edit',
  'repairFormPdf' = '/service-records/repair-form-pdf',
  'prepostTrip' = '/trip-inspections/prepost-trip',
  'obd2DevicesList' = '/obd2-devices',
  'preSummary' = '/trip-inspections/pretrip-summary',
  'postSummary' = '/trip-inspections/posttrip-summary',
  'postTrip' = '/trip-inspections/post-trip',
  'preTrip' = '/trip-inspections/pre-trip',
  'pretripDiagnostic' = '/trip-inspections/pretrip-diagnostic',
  'posttripDiagnostic' = '/trip-inspections/posttrip-diagnostic',
  'diagnosticsSummary' = '/diagnostics/summary',
  'reminderList' = '/reminders',
  'reminderDetails' = '/reminders/reminder-details',
  'usersList' = '/users',
  'chatMessage' = '/chat-message',
  'fleetHealth' = '/fleet-health',
  'fleetHealthVehicle' = '/fleet-health/vehicle-health',
  'statistics' = '/statistics',
  'profile' = '/profile',
  'profileEdit' = '/profile/edit',
  'notificationSettings' = '/profile/notification-settings',
  'notifications' = '/notifications',
  'notificationsObd2Event' = '/notifications/obd2-event',
  'notificationsPoiEvent' = '/notifications/poi-event',
  'notificationsGeofenceEvent' = '/notifications/geofence-event',
  'subscriptions' = '/subscriptions',
  'rental' = '/rental',
  'preRentalForm' = '/rental/pre-rental-add',
  'preRentalEdit' = '/rental/pre-rental-edit',
  'preRentalSummary' = '/rental/pre-rental-summary',
  'postRentalForm' = '/rental/post-rental-add',
  'postRentalEdit' = '/rental/post-rental-edit',
  'postRentalSummary' = '/rental/post-rental-summary',
  'maintenanceProfiles' = '/maintenance-profiles',
  'maintenanceProfilesAdd' = '/maintenance-profiles/add',
  'maintenanceProfilesEdit' = '/maintenance-profiles/edit',
  maintenanceReminders = 'maintenance-reminders',
  maintenanceReminderView = 'maintenance-reminders/view',
}
export enum eRRoute {
  'empty' = '',
  'all' = '**',
  add = 'add',
  edit = 'edit/:id',
  'auth' = 'auth',
  home = 'home',
  map = 'map',
  analytics = 'analytics',
  vehicleRoutes = 'vehicle-routes/:id',
  rabbitTrail = 'rabbit-trail',
  rabbitTrailActivity = 'rabbit-trail-activity',
  rabbitTrailDailyActivity = 'rabbit-trail-daily-activity',
  vehicleDailyActivity = 'vehicle-daily-activity/:id',
  pointOfInterestAdd = 'point-of-interest-add',
  pointOfInterestEdit = 'point-of-interest-edit/:id',
  fenceAdd = 'fence-add',
  fenceEdit = 'fence-edit/:id',
  'repairRequest' = 'repair-request',
  'reminderProfiles' = 'reminder-profiles',
  'vehicle' = 'vehicle',
  'signin' = 'signin',
  'signup' = 'signup',
  'forgot' = 'forgot-password',
  'enquiry' = 'enquiry',
  'it' = 'inspection-titles',
  'dtiles' = 'diagnostics-titles',
  'addReq' = 'add-request',
  'tripInspections' = 'trip-inspections',
  'manageVehicles' = 'manage-vehicles',
  'addDiagnostics' = 'add-diagnostics',
  'diagnostics' = 'diagnostics',
  'timesheet' = 'timesheet',
  'companies' = 'companies',
  'vtl' = 'video-training-library',
  'estimate' = 'estimate',
  'serviceRecords' = 'service-records',
  'obd2DevicesList' = 'obd2-devices',
  'reminders' = 'reminders',
  'usersList' = 'users',
  'chatMessage' = 'chat-message',
  'fleetHealth' = 'fleet-health',
  'statistics' = 'statistics',
  'profile' = 'profile',
  'profileEdit' = 'edit',
  'notificationSettings' = 'notification-settings',
  'notifications' = 'notifications',
  'subscriptions' = 'subscriptions',
  'twillio' = 'twillio',
  'rental' = 'rental',
  'harshEvents' = 'harsh-events',
  'timeline' = 'timeline',
  'OBD2Event' = 'obd2-event',
  'maintenanceProfiles' = 'maintenance-profiles',
  maintenanceReminders = 'maintenance-reminders',
}
